import React from 'react';
import FeatureSection from '../components/FeatureSection/FeatureSection';
import Hero from '../components/Hero/Hero';

const HomePage = () => {
	return (
		<>
			<Hero></Hero>
			<FeatureSection />
		</>
	);
};

export default HomePage;
