import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../globalStyles';

import {
    Nav,
    NavContainer,
    NavIcon,
    NavLogo,
    MobileIcon,
    NavBtnLink,
    NavItem,
    NavMenu,
    NavLinks,
    NavItemBtn,
} from './HeaderStyles';

const Navbar = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => {};

    const scrollTo = (id) => {
        setClick(false);

        const element = document.getElementById(id);

        element.scrollIntoView({
            behavior: 'smooth',
        });
    };

    // Define your navigation links here
    const navLinks = [
        // { to: '/', id: 'home', text: 'Home' },
        // { to: '/about', id: 'about', text: 'About' },
        // { to: '/services', id: 'services', text: 'Services' },
        // { to: '/contact', id: 'contact', text: 'Contact' },
    ];

    return (
        <>
            <Nav>
                <NavContainer>
                    <NavLogo to="/" onClick={closeMobileMenu}>
                        <NavIcon />
                        Finkee
                    </NavLogo>

                    <IconContext.Provider value={{ style: { color: '#fff' } }}>
                        <MobileIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>
                    </IconContext.Provider>

                    <NavMenu onClick={handleClick} click={click}>
                        {navLinks.map((el, index) => (
                            <NavItem key={index}>
                                <NavLinks to={el.to} onClick={() => scrollTo(el.id)}>
                                    {el.text}
                                </NavLinks>
                            </NavItem>
                        ))}
                    
                    <NavItemBtn>
                        <NavBtnLink href="https://t.me/finkeeio">
                        <Button onClick={() => window.location.href = "https://t.me/finkeeio"} fontBig>
                                        Join
                                    </Button>
                                    </NavBtnLink>
                    </NavItemBtn>
                    
                    </NavMenu>
                </NavContainer>
            </Nav>
        </>
    );
};

export default Navbar;
