import React, { useState } from 'react';
import { Input, Label } from './HeroStyles';
import styled from 'styled-components';
import Dropdown from './Dropdown/Dropdown';

const InputGroup = styled.div`
  display: flex;
  gap: 0;
  position: relative;
`;

const CurrencyInput = ({ sell, currency, value, setValue, setCurrency }) => {
  const [error, setError] = useState(null);

  const handleValueChange = (newValue) => {
    const minAllowedAmount = getMinAllowedAmountForCurrency(currency); 

    if (newValue < minAllowedAmount) {
      setError(`Minimum allowed amount for ${currency} is ${minAllowedAmount}`);
    } else {
      setError(null);
    }

    setValue(newValue);
  };

  const getMinAllowedAmountForCurrency = (currency) => {
    const minAmounts = {
      AED: 2000,
      CNY: 10000,
      IDR: 8000,
      INR: 45000,
      MYR: 2500,
      RUB: 50000,
      TRY: 15500,
    };

    return minAmounts[currency] || 0;
  };

  return (
    <InputGroup>
      <Label absolute bold>
        {sell ? 'Amount' : 'Converted to'}
      </Label>

      <Input
        type="number"
        onChange={(e) => handleValueChange(parseFloat(e.target.value))}
        value={value}
      />

      {error && (
        <div style={{ color: 'red', position: 'absolute', bottom: '-20px', left: '5px', marginTop: '35px' }}>
          {error}
        </div>
      )}

      <Dropdown currency={currency} setCurrency={setCurrency} />
    </InputGroup>
  );
};

export default CurrencyInput;