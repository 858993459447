import React, { useState, useEffect } from 'react';
import {
	RightColumn,
	ConversionList,
	Features,
	GetStarted,
	Title,
	Video,
	LeftColumn,
	HeroSection,
	SwitchButton,
} from './HeroStyles';
import CurrencyInput from './CurrencyInput';
import { Button, Container, Text, Row } from '../../globalStyles';
import { AiOutlineRise } from 'react-icons/ai';
import { IoSwapHorizontalSharp } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import { motion } from 'framer-motion';
import { getExchangeRates } from '../../data/api.js';

const Hero = () => {
	const [sellCur, setSellCur] = useState('IDR');
	const [buyCur, setBuyCur] = useState('CNY');
	const [rate, setRate] = useState(1);

	const [sellAmount, setSellAmount] = useState(1000);
	const [buyAmount, setBuyAmount] = useState(1000);

	const roundNumber = (number, decimal) => {
		if (decimal) {
			return (
				Math.round((number + Number.EPSILON) * Math.pow(10, decimal)) /
				Math.pow(10, decimal)
			);
		}
		return Math.round((number + Number.EPSILON) * 100) / 100;
	};

	useEffect(() => {
		const fetchExchangeRates = async () => {
			try {
				const exchangeRates = await getExchangeRates(sellCur);
				const curRate = exchangeRates[buyCur] / exchangeRates[sellCur];

				// Define custom fees for each currency pair
				const customFees = {
					'AED/CNY': 9,
					'AED/IDR': 5,
					'AED/INR': 5,
					'AED/MYR': 5,
					'AED/RUB': 5,
					'AED/TRY': 5,
					'AED/USDT': 5,
					'CNY/AED': 9,
					'CNY/IDR': 9,
					'CNY/INR': 9,
					'CNY/MYR': 9,
					'CNY/RUB': 9,
					'CNY/TRY': 9,
					'CNY/USDT': 9,
					'IDR/AED': 5,
					'IDR/CNY': 9,
					'IDR/INR': 5,
					'IDR/MYR': 5,
					'IDR/RUB': 5,
					'IDR/TRY': 5,
					'IDR/USDT': 5,
					'INR/AED': 5,
					'INR/CNY': 9,
					'INR/IDR': 5,
					'INR/MYR': 5,
					'INR/RUB': 5,
					'INR/TRY': 5,
					'INR/USDT': 5,
					'MYR/AED': 5,
					'MYR/CNY': 9,
					'MYR/IDR': 5,
					'MYR/INR': 5,
					'MYR/RUB': 5,
					'MYR/TRY': 5,
					'MYR/USDT': 5,
					'RUB/AED': 5,
					'RUB/CNY': 9,
					'RUB/IDR': 5,
					'RUB/INR': 5,
					'RUB/MYR': 5,
					'RUB/TRY': 5,
					'RUB/USDT': 5,
					'TRY/AED': 5,
					'TRY/CNY': 9,
					'TRY/IDR': 5,
					'TRY/INR': 5,
					'TRY/MYR': 5,
					'TRY/RUB': 5,
					'TRY/USDT': 5,
					'USDT/AED': 5,
					'USDT/CNY': 9,
					'USDT/IDR': 5,
					'USDT/INR': 5,
					'USDT/MYR': 5,
					'USDT/RUB': 5,
					'USDT/TRY': 5,
				};

				const currencyPair = `${sellCur}/${buyCur}`;
				const customFee = customFees[currencyPair] || 0;  // Default to 0 if no custom fee defined

				// Calculate the "Converted to" amount directly as "Amount - Our Fee"
				const convertedToAmount = roundNumber(sellAmount - sellAmount * (customFee / 100), 2);

				setBuyAmount(convertedToAmount);
				setRate(roundNumber(curRate, 5));
			} catch (error) {
				console.error('Error fetching exchange rates:', error.message);
				// Handle errors
			}
		};

		fetchExchangeRates();
	}, [buyCur, sellCur, sellAmount]);

	const handleCurrencySwitch = () => {
		// Swap the values of sellCur and buyCur
		setSellCur(buyCur);
		setBuyCur(sellCur);
	};


	return (
		<HeroSection id="hero">
			<Container>
				<Row justify="center">
					<LeftColumn width="50%">
						<Title bold textColor>
							Cheapest currency
							<br />
							exchange Platform
						</Title>
						<Text fontSize="0.9" space>
							Transfer money or spend abroad cheaper and faster <br />
							than old-school banks, and receive
							<br /> money around the world for free.
						</Text>
						<Video
							width="70%"
							src="/videos/Hero.mp4"
							type="video/mp4"
							autoPlay
							muted
							loop
						></Video>
						<Features>
							<div>
								<img className="lock" src="./images/Lock.svg" alt="21" />
								<Text bold>Safest Platform</Text>
								{/* <a href="localhost:3000">Find out why</a> */}
							</div>
							<div>
								<img className="reviews" src="./images/Reviews.svg" alt="21" />
								<Text bold>Millions of Happy Customers</Text>
								{/* <a href="localhost:3000">See what they say</a> */}
							</div>
						</Features>
					</LeftColumn>

					<RightColumn align="end" md={5} sm={10}>
						<div>
							<Row>
								<CurrencyInput
									sell={true}
									setValue={setSellAmount}
									value={sellAmount}
									currency={sellCur}
									setCurrency={setSellCur}
								/>
							</Row>

							<SwitchButton onClick={handleCurrencySwitch}>
								<IoSwapHorizontalSharp />

							</SwitchButton>


							<Row>
								<CurrencyInput
									currency={buyCur}
									setValue={setBuyAmount}
									value={buyAmount}
									setCurrency={setBuyCur}
								/>
							</Row>

							<ConversionList>
								<li>
									<span>
										{roundNumber(sellAmount * 0.05)} {sellCur}
									</span>
									<span>Our Fee</span>
								</li>

								<li>
									<span>
										{sellAmount - roundNumber(sellAmount * 0.0066)} {sellCur}
									</span>
									<span>Amount we convert</span>
									<div>=</div>
								</li>
								<li>
									<span className="rate">
										{rate}
										<motion.span
											initial={{ opacity: '0' }}
											transition={{
												yoyo: Infinity,
												duration: 2,
											}}
											animate={{ opacity: 1 }}
										>
											<IconContext.Provider
												value={{ size: '1.7rem', color: '#23ca8f' }}
											>
												<AiOutlineRise />
											</IconContext.Provider>
										</motion.span>
									</span>
									<span>Guaranteed rate (24 hrs)</span>
									<div>x</div>
								</li>
							</ConversionList>
							<GetStarted>
								{/* <div>
									<Text bold fontSize="0.9rem">
										You would save vs banks
										<br />
										<Text bold fontSize="1.1rem">
											6.32 USD
										</Text>
									</Text>
									<Text bold fontSize="0.9rem">
										Should arrive by
										<br />
										<Text bold fontSize="1.1rem">
											August 23
										</Text>
									</Text>
								</div> */}
								<Button color="white" hoverColor="#321321">
									<a href="https://t.me/finkeeio" style={{ textDecoration: 'none' }}><span>Get Started</span></a>
								</Button>
							</GetStarted>
						</div>
					</RightColumn>
				</Row>
			</Container>
		</HeroSection>
	);
};

export default Hero;