import React from 'react';
import { Container } from '../../globalStyles';
import { FooterSection, FooterRow, FooterColumn } from './FooterStyles';

const Footer = () => {
  return (
    <FooterSection id="help">
      <Container>
        <FooterRow>
          <FooterColumn>
            <span>Finkee</span>
          </FooterColumn>
        </FooterRow>
      </Container>
    </FooterSection>
  );
};

export default Footer;
