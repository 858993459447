// src/utils/api.js
const apiKey = 'df3580c2c5bf61177e860084';

export const getExchangeRates = async (baseCurrency) => {
  const apiUrl = `https://v6.exchangerate-api.com/v6/${apiKey}/latest/${baseCurrency}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch exchange rates: ${response.statusText}`);
    }

    const data = await response.json();
    return data.conversion_rates;
  } catch (error) {
    console.error('Error fetching exchange rates:', error.message);
    throw error;
  }
};
