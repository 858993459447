import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Container } from 'react-bootstrap';
import Flag from 'react-flagkit';
import { AiOutlineSearch } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import data from '../../data/data.json';
import { CountryInput, Label, List, ListItem } from './HeroStyles';
import { AnimatePresence } from 'framer-motion';

const DropdownList = ({ closeDropdown, show, listRef }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!show) {
      setSearch('');
    }
  }, [show]);

  const handleSearch = (searchText) => {
    setSearch(searchText);
  };

  return (
    <AnimatePresence>
      {show && (
        <List
          initial={{ opacity: 0, height: '0%' }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0 }}
          className="dropdown-list"
        >
          <Container ref={listRef}>
            <ListItem padding="0" noHover>
              <IconContext.Provider value={{ size: '2em', color: '#c9c9c9' }}>
                <AiOutlineSearch />
              </IconContext.Provider>
              <CountryInput
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Type a currency"
                className="ml-5"
                bc="#fff"
                type="text"
              />
            </ListItem>

            {data
              .filter((el) => {
                const searchText = search.trim().toLocaleLowerCase();
                const name = el.name.toLocaleLowerCase().trim().includes(searchText);
                const code = el.cur.toLocaleLowerCase().trim().includes(searchText);

                return code || name;
              })
              .map((el, index) => (
                <ListItem key={index} onClick={() => closeDropdown(el)}>
                  <Flag size={28} country={el.cur.slice(0, -1)} /> <span>{el.cur}</span>
                  <Label fontSize="1em">{el.name}</Label>
                </ListItem>
              ))}
          </Container>
        </List>
      )}
    </AnimatePresence>
  );
};

// PropTypes validation
DropdownList.propTypes = {
  closeDropdown: PropTypes.func.isRequired, // closeDropdown should be a function and is required
  show: PropTypes.bool.isRequired, // show should be a boolean and is required
  listRef: PropTypes.oneOfType([ // listRef can be either a function or an object
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};

export default DropdownList;
